<template>
    <div class="content" style="padding-top: 80px">

        <div class="row">
            <div class="col-sm-12 col-md-8 offset-md-2 col-lg-4 offset-lg-4">
                <div class="card text-left">
                    <div class="card-header">
                        <h5 class="mg-b-0-f">Нэвтрэх</h5>
                    </div>
                    <div class="card-body">
                        
                        <form @submit.prevent="signIn">
                            <!-- Email input -->
                            <div class="form-outline mb-4">
                                <input v-model="loginModel.username" type="text" id="form2Example1" class="form-control" />
                                <label class="form-label" for="form2Example1">Нэвтрэх нэр эсвэл и-мэйл хаяг</label>
                            </div>

                            <!-- Password input -->
                            <div class="form-outline mb-4">
                                <input v-model="loginModel.password" type="password" id="form2Example2" class="form-control" />
                                <label class="form-label" for="form2Example2">Нууц үг</label>
                            </div>

                            <!-- 2 column grid layout for inline styling -->
                            <div class="row mb-4">
                                <div class="col d-flex justify-content-center">
                                <!-- Checkbox -->
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="form2Example31" checked />
                                    <label class="form-check-label" for="form2Example31"> сануулах </label>
                                </div>
                                </div>

                                <div class="col">
                                <!-- Simple link -->
                                <a href="#!">Нууц үг мартсан?</a>
                                </div>
                            </div>

                            <!-- Submit button -->
                            <button type="submit" class="btn btn-primary btn-block mb-4">Нэвтрэх</button>

                            <!-- Register buttons -->
                            <div class="text-center">
                                <p>бүртгэлгүй бол ? <a href="#!">Бүртгүүлэх</a></p>
                                
                            </div>
                            </form>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import { request } from '@/api/all';
import { mapActions } from "vuex";

    export default{
        data(){
            return{
                loginModel: {
                    loading: null,
                    username: "",
                    password: "",
                },
            }
        },
        methods:{
            ...mapActions([
                "handleLoading",
                "handleLogin",
                "handleUserInfo",
                "handleGroupSettings",
                "handleUsersettings",
                "handleGroupDefaultProjects",
                "handleRoutes",
                "handleSignalR"
            ]),
            signIn(){
                //console.log("opt=> ", opt);
                this.handleLoading({ isLoading: true });
                this.handleLogin({
                    user: this.loginModel.username,
                    password: this.loginModel.password,
                    //key: _key
                    key: this.$store.state.key,
                }).then((res) => {
                    this.handleLoading({ isLoading: false });
                    if (res != false) {
                        this.loginAfter(res);
                    }else {
                        console.log('res', res);
                    }
                });
            },
            loginAfter() {
                this.handleLoading({ isLoading: true });
                this.handleUserInfo({ store: this.$store }).then((irs) => {
                    if (irs != false) {
                        this.handleGroupSettings({ store: this.$store }).then((stt) => {
                        if (stt != false) {
                            this.handleUsersettings({ store: this.$store }).then((ustt) => {
                            if (ustt != false) {
                                this.handleGroupDefaultProjects({
                                store: this.$store,
                                }).then((res) => {
                                if (res != false) {
                                    this.handleSignalR({ store: this.$store })
                                        .then(() => {
                                            this.handleLoading({ isLoading: false });
                                            this.$router.push({ name: 'profile' });
                                        })
                                        .catch((ex) => {
                                            //console.log('ex', ex);
                                            this.handleLoading({ isLoading: false });
                                            // this.$bvToast.toast(
                                            // `Мессеж хүлээн авах сокетын холболт амжилтгүй боллоо.`,
                                            // {
                                            //     title: "Мэдэгдэл",
                                            //     autoHideDelay: 15000,
                                            //     appendToast: true,
                                            // }
                                            // );

                                            // this.$router.push("/profile");

                                        });
                                }else{
                                    this.handleLoading({ isLoading: false });
                                }
                                });
                            }else{
                                this.handleLoading({ isLoading: false });
                            }
                            });
                        }else{
                            this.handleLoading({ isLoading: false });
                        }
                        });
                    }else{
                        this.handleLoading({ isLoading: false });
                    }
                });

            },
        }
    }
</script>
<style>
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
</style>